<template>
  <v-list nav>
    <v-list-item-group>
      <template>
        <v-list-item :to="{ name: 'Home' }">
          <v-list-item-action>
            <v-icon>keyboard_return</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
      </template>

      <v-list-item v-if="vueVariaveis" :to="{ name: 'Configs' }">
        <v-list-item-action>
          <v-icon>code</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Variaveis</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuSistema",
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueVariaveis() {
      return this.getAccess("vueVariaveis", "menu");
    },
  },
};
</script>

<style></style>
